import Post from '../post'

function IndexPage() {
    let imageurl = "https://media.istockphoto.com/id/491520707/photo/sample-red-grunge-round-stamp-on-white-background.jpg?s=612x612&w=is&k=20&c=B6b-c32-KT3Q2ghVxs3mOR6PTSgFfABdck1l82rPqN8="

    return (

        <main className=''>
                <Post title="fsds" imageurl={imageurl}></Post>
                <Post title="stuff" imageurl={imageurl}></Post>
                <Post title={"moo"} imageurl={imageurl}></Post>
            
        </main>)
}

export default IndexPage