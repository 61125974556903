import Login from './pages/LoginPage';
import Navbar from './Navbar';
import IndexPage from './pages/IndexPage';
import AdminPage from './pages/AdminPage';
import { Route, Routes} from 'react-router-dom'
function App() {

  return (
    <>
    <p>
    {/* hello */}
    </p>
    <Navbar></Navbar>
       <Routes>
         <Route path="/login" element={<Login />}>Login</Route>
         <Route path="/admin" element={<AdminPage />}></Route>

         {/* <Route path="/register" element = {<RegisterPage/>}></Route> */}
         <Route index element={<IndexPage></IndexPage>
    
         }></Route>

       </Routes>
    </>

  );
}

export default App;
