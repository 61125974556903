
import { GLOBALS } from '../../CONFIG'
function Login() {

  return (
    <div className='flex content-center justify-center'>

      <a href={GLOBALS.discord_outh_location}>Dicksword Authentication</a>
      <form action="" className='test '>

        <input type="text" placeholder='username' className='mb-5 outline-double'></input>
        <br></br>
        <input type="password" placeholder='password' className=' mb-5 outline-double'></input><br>
        </br>
        <button className='outline-double'>Login</button>
      </form>
    </div>

  )
}

export default Login